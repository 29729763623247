<template>
    <div>
        <form v-if="!check">
            <input v-model="login" type="text" class="form-control mb-2" placeholder="Login">
            <input v-model="password" type="password" class="form-control mb-2" placeholder="Heslo">
            <button @click="process" :disabled="loading" class="btn btn-outline-success mb-2 form-control">
                <span v-if="!loading && !processed">Potvrdiť</span>
                <div v-if="loading">
                    <svg v-if="processed" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>

                    <div v-else class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </button>
        </form>

        <form v-else>
            <input v-model="installLogin" type="text" class="form-control mb-2" placeholder="Login">
            <input v-model="installName" type="text" class="form-control mb-2" placeholder="Meno">
            <input v-model="installSN" type="text" class="form-control mb-2" placeholder="Priezvisko">
            <input v-model="installPassword" type="password" class="form-control mb-2" placeholder="Heslo">
            <input v-model="installPasswordR" type="password" class="form-control mb-2" placeholder="Zopakujte Heslo">
            <button @click="installProcess" :disabled="loading" class="btn btn-outline-success mb-2 form-control">
                <span v-if="!loading && !processed">Vytvoriť</span>
                <div v-if="loading">
                    <svg v-if="processed" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>

                    <div v-else class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </button>
        </form>
    </div>
</template>
<script>
import toastr from 'toastr';
export default {
    data(){
        return {
            login: '',
            password: '',
            loading: false,
            processed: false,
            check: false,
            installLogin: '',
            installPassword: '',
            installPasswordR: '',
            installName: '',
            installSN: '',
        }
    },
    mounted() {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "200",
            "hideDuration": "500",
            "timeOut": "2000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        this.checkForInstall();
    },
    methods: {
        checkForInstall(){
            axios.get('/installcheck').then(response => {
                if (response){
                    this.check = response.data.cond;
                }
            })
        },
        process(){
            this.loading = true;
            axios.post('/login', {
                login: this.login,
                password: this.password,
            }).catch(error => {
                toastr.error(error.response.data.message)
                this.loading = false;
            }).then(response => {
                if (response){
                    this.processed = true;

                    setTimeout(() => {
                        window.location.replace("/");
                    }, 1000)
                }
            })
        },
        installProcess(){
            this.loading = true;

            let cond = false;
            if (this.installLogin == ''){
                toastr.error('Pole Login nesmie byť prázdne')
                cond = true;
            }

            if (this.installName == ''){
                toastr.error('Pole Meno nesmie byť prázdne')
                cond = true;
            }

            if (this.installSN == ''){
                toastr.error('Pole Priezvisko nesmie byť prázdne')
                cond = true;
            }

            if (this.installPassword == ''){
                toastr.error('Pole Heslo nesmie byť prázdne')
                cond = true;
            }

            if (this.installPassword == ''){
                toastr.error('Pole Zopakujte Heslo nesmie byť prázdne')
                cond = true;
            }

            if (this.installPassword != this.installPasswordR){
                toastr.error('Heslá sa nezhodujú')
                cond = true;
            }

            if (this.installLogin.length > 255){
                toastr.error('Pole Login nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.installName.length > 255){
                toastr.error('Pole Meno nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.installSN.length > 255){
                toastr.error('Pole Priezvisko nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.installPassword.length > 255){
                toastr.error('Pole Heslo nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (cond){
                return;
            }

            axios.post('/install', {
                login: this.installLogin,
                password: this.installPassword,
                name: this.installName,
                sn: this.installSN,
            }).catch(error => {
                toastr.error(error.response.data.message)
                this.loading = false;
            }).then(response => {
                if (response){
                    this.processed = true;

                    setTimeout(() => {
                        window.location.replace("/");
                    }, 1000)
                }
            })
        }
    }
}
</script>
