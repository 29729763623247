<style>
    .addedPlayer{
        transition: all .5s ease-in;
    }

    .addedPlayer:hover{
        cursor: pointer;
        background-color: red;
        color: white;
    }
</style>

<template>
    <div>
        <div class="d-flex flex-wrap justify-content-center">
            <a style="position: relative; top: 0; left: 0; margin: 10px">
                <button @click="collapseToggle('players')" class="btn btn-outline-dark shadow" style="border-radius: unset">Vytvoriť hráča</button>
            </a>

            <a style="position: relative; top: 0; left: 0; margin: 10px">
                <button @click="collapseToggle('tournaments')" class="btn btn-outline-dark shadow" style="border-radius: unset">Zapísať turnaj</button>
            </a>
        </div>

        <div class="collapse" id="players">
            <form style="margin-top: 1rem" @submit.prevent="process">
                <input v-if="player.admin" v-model="player.login" type="text" class="form-control mb-2" placeholder="Login">
                <input v-model="player.name" type="text" class="form-control mb-2" placeholder="Meno">
                <input v-model="player.sn" type="text" class="form-control mb-2" placeholder="Priezvisko">
                <input v-if="player.admin" v-model="player.password" type="password" class="form-control mb-2" placeholder="Heslo">
                <input v-if="player.admin" v-model="player.passwordR" type="password" class="form-control mb-2" placeholder="Zopakujte Heslo">
                <div class="mb-2">
                    <input v-model="player.admin" type="checkbox" id="player.admin">
                    <label for="player.admin">Vytvoriť ako administrátora</label>
                </div>
                <button @click="process('player')" :disabled="player.loading" class="btn btn-outline-success mb-2 form-control">
                    <span v-if="!player.loading">Vytvoriť</span>
                    <div v-else class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </button>
            </form>
        </div>

        <div class="collapse" id="tournaments">
            <form style="margin-top: 1rem" @submit.prevent="process">
                <input v-model="tournament.place" type="text" class="form-control mb-2" placeholder="Miesto">
                <input v-model="tournament.date" type="datetime-local" class="form-control mb-2">
                <div v-if="tournament.addedUsers.length > 0">
                    <input class="form-control mb-2 addedPlayer" type="text" v-for="player in tournament.addedUsers" @click="removePlayer(player.id)" :value="player.fullName + ' => ' + player.score" readonly>
                </div>
                <input type="button" value="Pridať hráča" class="form-control mb-2 btn btn-outline-primary" @click="addPlayer()">
                <button @click="process('tournament')" :disabled="tournament.loading" class="btn btn-outline-success mb-2 form-control">
                    <span v-if="!tournament.loading">Zapísať</span>
                    <div v-else class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </button>
            </form>
        </div>

        <div id="playerAddModal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Pridať hráča</h5>
                    </div>
                    <div class="modal-body">
                        <select v-model="tournament.addUser" class="form-control mb-2">
                            <option value="">Vyberte hráča</option>
                            <option v-for="player in playersMutable" v-if="player" :value="player.id">{{player.name}} {{player.sn}}</option>
                        </select>

                        <input type="number" v-model="tournament.addUserScore" class="form-control" placeholder="Počet bodov" min="0">
                    </div>
                    <div class="modal-footer">
                        <button :disabled="tournament.addUser == '' || !tournament.addUserScore" type="button" @click="submitPlayer()" class="btn btn-primary">Pridať</button>
                        <button type="button" @click="addPlayer" class="btn btn-secondary">Zavrieť</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toastr from "toastr";

export default {
    data(){
        return {
            playersMutable: [],
            players: [],
            player: {
                login: '',
                name: '',
                sn: '',
                password: '',
                passwordR: '',
                loading: false,
                admin: false,
            },

            tournament: {
                place: '',
                addUser: '',
                date: '',
                addUserScore: null,
                addedUsers: [],
                loading: false,
            },
        }
    },
    mounted() {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "200",
            "hideDuration": "500",
            "timeOut": "2000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        this.getPlayers();
    },
    methods: {
        getPlayers(){
            axios.get('/admin/players').then(response => {
                this.players = [...response.data.players]
                this.playersMutable = [...this.players]
            })
        },
        removePlayer(player){
            if (player){
                let cond = false;
                this.tournament.addedUsers.forEach(user => {
                    if (user.id == player){
                        cond = true;
                    }
                })

                if (cond){
                    let playerIndex;
                    this.tournament.addedUsers.forEach((object, index) => {
                        if (object){
                            if (object.id == player){
                                playerIndex = index
                            }
                        }
                    });

                    let playerData
                    this.players.forEach(user => {
                        if (user.id == player){
                            playerData = user;
                        }
                    })

                    this.tournament.addedUsers.splice(playerIndex, 1);
                    this.playersMutable.push(playerData);
                }
            }
        },
        submitPlayer(){
            if (this.tournament.addUser != ''){
                let player = this.playersMutable.filter(player => {
                    if (player){
                        return player.id == this.tournament.addUser
                    }
                })

                if (player.length > 0){

                    let cond = false;
                    if (!this.tournament.addUserScore){
                        toastr.error('Počet bodov je povinný')
                        cond = true;
                    }

                    if (this.tournament.addUserScore < 0){
                        toastr.error('Počet bodov nesmie byť záporný')
                        cond = true;
                    }

                    if (cond){
                        return;
                    }

                    const index = this.playersMutable.findIndex(object => {
                        if (object){
                            return object.id == player[0].id;
                        }
                    });

                    this.tournament.addedUsers.push({id: player[0].id, fullName: player[0].name + ' ' + player[0].sn, score: this.tournament.addUserScore});

                    delete this.playersMutable[index];

                    this.tournament.addUser = '';
                    this.tournament.addUserScore = null;

                    $('#playerAddModal').modal('toggle');
                }
            }
        },
        addPlayer(){
            $('#playerAddModal').modal('toggle');
        },
        collapseToggle(el){
            $('#' + el).collapse('toggle')
        },
        process(type){
            switch (type){
                case 'player':
                    if (this.validatePlayer()) return;
                    this.player.loading = true;

                    axios.post('/admin', {
                        type: 'player',
                        data: this.player
                    }).catch(error => {
                        this.resetPlayer()
                        toastr.error(error.response.data.message);
                        this.player.loading = false;
                    }).then(response => {
                        if (response){
                            this.resetPlayer()

                            toastr.success('Uložené')
                            this.player.loading = false;
                        }
                    })
                    break;
                case 'tournament':
                    if (this.validateTournament()) return;
                    this.tournament.loading = true;

                    axios.post('/admin', {
                        type: 'tournament',
                        data: this.tournament
                    }).catch(error => {
                        this.resetTournament()
                        toastr.error(error.response.data.message);
                        this.tournament.loading = false;
                    }).then(response => {
                        if (response){
                            this.resetTournament()
                            toastr.success('Uložené')
                            this.tournament.loading = false;
                        }
                    })
                    break;
            }
        },
        resetTournament(){
            this.tournament = {
                place: '',
                addUser: '',
                date: '',
                addUserScore: null,
                addedUsers: [],
                loading: false,
            }

            this.playersMutable = [...this.players]
        },
        resetPlayer(){
            this.player = {
                login: '',
                name: '',
                sn: '',
                password: '',
                passwordR: '',
                loading: false,
                admin: false,
            }
            this.resetTournament();
            this.getPlayers();
        },
        validateTournament(){
            let cond = false;

            if (this.tournament.place == ''){
                toastr.error('Pole Miesto nesmie byť prázdne')
                cond = true;
            }

            if (this.tournament.place.length > 255){
                toastr.error('Pole Miesto nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.tournament.date == ''){
                toastr.error('Pole Dátum nesmie byť prázdne')
                cond = true;
            }

            return cond;
        },
        validatePlayer(){
            let cond = false;

            if (this.player.name == ''){
                toastr.error('Pole Meno nesmie byť prázdne')
                cond = true;
            }

            if (this.player.sn == ''){
                toastr.error('Pole Priezvisko nesmie byť prázdne')
                cond = true;
            }

            if (this.player.name.length > 255){
                toastr.error('Pole Meno nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.player.sn.length > 255){
                toastr.error('Pole Priezvisko nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.player.admin){
                if (this.player.login == ''){
                    toastr.error('Pole Login nesmie byť prázdne')
                    cond = true;
                }

                if (this.player.password == ''){
                    toastr.error('Pole Heslo nesmie byť prázdne')
                    cond = true;
                }

                if (this.player.passwordR == ''){
                    toastr.error('Pole Zopakujte Heslo nesmie byť prázdne')
                    cond = true;
                }

                if (this.player.password != this.player.passwordR){
                    toastr.error('Heslá sa nezhodujú')
                    cond = true;
                }

                if (this.player.login.length > 255){
                    toastr.error('Pole Login nesmie byť dlhšie ako 255 znakov')
                    cond = true;
                }

                if (this.player.password.length > 255){
                    toastr.error('Pole Heslo nesmie byť dlhšie ako 255 znakov')
                    cond = true;
                }
            }

            return cond;
        }
    },
}
</script>
