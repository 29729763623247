<style>
.pagination{
    display: flex !important;
    justify-content: center;
    width: 100%;
}

.pagination li{
    padding: 0 !important;
}

.addedPlayer{
    transition: all .5s ease-in;
}

.addedPlayer:hover{
    cursor: pointer;
    background-color: red;
    color: white;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
</style>

<template>
    <div :key="key" class="w-100 d-flex flex-wrap justify-content-center">
        <div class="card shadow p-3 col-12 mb-2" v-for="(tournament) in tournaments.data">
            <div style="cursor: pointer; font-weight: bold" @click="collapse('T' + tournament.id)">
                <h2 style="margin: 0">{{tournament.place}}</h2>
                <p style="color: grey">{{tournament.date}}</p>
            </div>
            <div class="collapse" :id="'T' + tournament.id">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Meno</th>
                            <th scope="col">Priezvisko</th>
                            <th scope="col">Počet bodov</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(user, index) in tournament.tournament_users">
                            <th scope="row">{{index + 1}}</th>
                            <td>{{ user.user.name }}</td>
                            <td>{{ user.user.sn }}</td>
                            <td>{{ user.score }}</td>
                        </tr>
                    </tbody>
                </table>

                <button @click="edit(tournament)" class="btn btn-outline-primary" v-if="user">Upraviť</button>
            </div>
        </div>

        <pagination :limit="1" :data="tournaments" @pagination-change-page="getData" style="flex-wrap: wrap; padding-top: 2rem">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
        </pagination>

        <div v-if="user != []" id="editModel" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Upraviť</h5>
                    </div>
                    <div class="modal-body">
                        <input type="text" class="form-control mb-2" v-model="itemToEdit.place" placeholder="Miesto">
                        <input type="datetime-local" class="form-control mb-2" v-model="itemToEdit.date">
                        <select v-model="addUser" class="form-control mb-2">
                            <option value="">Vyberte hráča</option>
                            <option v-for="player in playersMutable" v-if="player" :value="player.id">{{player.name}} {{player.sn}}</option>
                        </select>

                        <input v-if="addUser != ''" type="number" v-model="addUserScore" class="form-control mb-2" placeholder="Počet bodov" min="0">
                        <input @click="addPlayer" v-if="addUser != '' && addUserScore && addUserScore >= 0" type="button" class="form-control mb-2 btn btn-outline-primary" value="Pridať">

                        <div v-if="itemToEdit.tournament_users">
                            <input class="form-control mb-2 addedPlayer" type="text" v-for="player in itemToEdit.tournament_users" @click="removePlayer(player.user.id)" :value="player.user.name + ' ' + player.user.sn + ' => ' + player.score" readonly>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="submitEdit()" class="btn btn-primary">Uložiť</button>
                        <button type="button" @click="closeModal()" class="btn btn-secondary">Zavrieť</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toastr from "toastr";

Vue.component('pagination', require('laravel-vue-pagination'));

var moment = require('moment');

export default {
    data(){
        return {
            tournaments: {},
            itemToEdit: {},
            addUser: '',
            addUserScore: null,
            playersMutable: [],
            players: [],
            key: 0,
        }
    },
    mounted() {
        this.getData();
        this.getPlayers();
    },
    methods: {
        validateTournament() {
            let cond = false;

            if (this.itemToEdit.place == ''){
                toastr.error('Pole Miesto nesmie byť prázdne')
                cond = true;
            }

            if (this.itemToEdit.place.length > 255){
                toastr.error('Pole Miesto nesmie byť dlhšie ako 255 znakov')
                cond = true;
            }

            if (this.itemToEdit.date == ''){
                toastr.error('Pole Dátum nesmie byť prázdne')
                cond = true;
            }

            return cond;
        },
        submitEdit(){
            if (this.validateTournament()) return;

            axios.put('/turnaje', {
                data: this.itemToEdit
            }).catch(error => {
                toastr.error(error.response.data.message);
            }).then(response => {
                if (response){
                    this.tournaments.data.forEach((item, index) => {
                        if (item.id == response.data.tournament.id){
                            this.tournaments.data[index] = response.data.tournament
                            this.tournaments.data[index].dateOG = moment(this.tournaments.data[index].date).format('YYYY-MM-DDTHH:mm');
                            this.tournaments.data[index].date = moment(this.tournaments.data[index].date).format('DD.MM.YYYY HH:mm');
                        }
                    });

                    toastr.success('Uložené')
                    this.key++;
                    this.closeModal()
                }
            })
        },
        addPlayer(){
            if (this.addUser != ''){
                let player = this.playersMutable.filter(player => {
                    if (player){
                        return player.id == this.addUser
                    }
                })

                if (player.length > 0){

                    let cond = false;
                    if (!this.addUserScore){
                        toastr.error('Počet bodov je povinný')
                        cond = true;
                    }

                    if (this.addUserScore < 0){
                        toastr.error('Počet bodov nesmie byť záporný')
                        cond = true;
                    }

                    if (cond){
                        return;
                    }

                    const index = this.playersMutable.findIndex(object => {
                        if (object){
                            return object.id == player[0].id;
                        }
                    });

                    this.itemToEdit.tournament_users.push({
                        score: this.addUserScore,
                        user_id: this.addUser,
                        user: {
                            id: this.addUser,
                            name: player[0].name,
                            sn: player[0].sn
                        },
                        local: true,
                    });

                    delete this.playersMutable[index];

                    this.addUser = '';
                    this.addUserScore = null;
                }
            }
        },
        removePlayer(player){
            if (player){
                let cond = false;
                this.itemToEdit.tournament_users.forEach(user => {
                    if (user.user.id == player){
                        cond = true;
                    }
                })

                if (cond){
                    let playerIndex;
                    this.itemToEdit.tournament_users.forEach((object, index) => {
                        if (object){
                            if (object.user.id == player){
                                playerIndex = index
                            }
                        }
                    });

                    let playerData
                    this.players.forEach(user => {
                        if (user.id == player){
                            playerData = user;
                        }
                    })

                    this.itemToEdit.tournament_users.splice(playerIndex, 1);
                    this.playersMutable.push(playerData);
                }
            }
        },
        getPlayers(){
            if (this.user){
                axios.get('/admin/players').then(response => {
                    this.players = [...response.data.players]
                    this.playersMutable = [...this.players]
                })
            }
        },
        closeModal(){
            $('#editModel').modal('toggle');
        },
        edit(item){
            this.playersMutable = [...this.players];

            if (this.user != [] && item){
                $('#editModel').modal('toggle');

                this.itemToEdit = JSON.parse(JSON.stringify(item));
                this.itemToEdit.date = this.itemToEdit.dateOG
                this.itemToEdit.tournament_users.forEach(item => {
                    const index = this.playersMutable.findIndex(object => {
                        if (object){
                            return object.id == item.user.id;
                        }
                    });

                    delete this.playersMutable[index];
                })
            }
        },
        collapse(el) {
            $('#' + el).collapse('toggle')
        },
        getData(page = 1){
            axios.get('/turnaje/data?page=' + page).then(response => {
                this.tournaments = response.data.tournaments;
                this.tournaments.data.forEach(item => {
                    item.dateOG = moment(item.date).format('YYYY-MM-DDTHH:mm');
                    item.date = moment(item.date).format('DD.MM.YYYY HH:mm')
                })
            })
        }
    },
    props: {
        user: {
            required: false,
        }
    }
}
</script>
